<template>
  <div>
    <div class="bg_top_linear-gradient" style="background: #1757AF;height: auto;width: auto;">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" color="#fff" @click="backPath" />
        <div class="top_name" style="color: #fff;">手机注册</div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 验证手机号是否相同 -->
    <div class="page" style="padding: 0.27rem;margin: 0rem;background:#1757AF">
      <!-- 营业执照 -->
      <div class="certificationReg_img_box" style="height: auto;padding-bottom: 0.3rem;">
        <div class="box_top flex-r-sb-c">
          <div class="img_name" style="margin-left: 0.3rem;font-weight: bold;">营业执照</div>
        </div>
        <van-image width="6.29rem" height="3.68rem" :src="regStepFrom.ocrUrl" />
        <van-form style="padding-top: 0.3rem;">
          <van-field v-model="regStepFrom.companyName" label="公司名称" placeholder="公司名称" readonly />
          <van-field v-model="regStepFrom.businessLicense" label="三证合一码" readonly placeholder="三证合一码" />
          <van-field v-model="regStepFrom.legalPerson" label="法人姓名" readonly placeholder="法人姓名" />
        </van-form>
      </div>
      <!-- 注册信息 -->
      <div class="certificationReg_img_box" style="height: auto;margin-top: 0.3rem;padding-bottom: 0.3rem;">
        <div class="box_top flex-r-sb-c">
          <div class="img_name" style="margin-left: 0.3rem;font-weight: bold;">手机注册</div>
        </div>
        <van-form>
          <van-field readonly clickable :value="regStepFrom.districtName" label="所在地区" placeholder="请选择所在地区"
                     right-icon="arrow" @click="showPicker = true"
          />
          <van-field v-model="regStepFrom.mobile" label="手机号码" placeholder="请填写法人实名认证的手机号码" />
          <van-field v-model="regStepFrom.checkCode" label="验证码" type="digit" class="van-field" name="checkCode"
                     placeholder="请输入验证码" maxlength="6" :rules="[{ required: true, message: '' }]"
          >
            <template #extra>
              <van-count-down v-if="isSend" :time="time" format="ss 秒重新发送" @finish="finish" />
              <div v-else class="send_message" style="font-size: 0.37rem;color: #0767AB;" @click="onSubmit">获取验证码</div>
            </template>
          </van-field>
          <div class="login_sub" style="margin-top: 0.9rem;margin-left: 0.2rem; ">
            <van-image width="7.63rem" height="1.81rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/invite/invite3_btn_02.png"
                       @click="regMobile"
            />
          </div>
        </van-form>
      </div>
    </div>
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
    <!-- 手机非法人实名号码 -->
    <van-dialog v-model="mobileShow01" :show-confirm-button="false">
      <div class="mobileShow_dialog flex-c-c-c">
        <van-image width="3.89rem" height="3.04rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/certification.png"
        />
        <div class="certification_title"> 手机非法人实名号码 </div>
        <div class="certification_tip"> 请填写法人实名认证的手机号注册 </div>
        <van-button class="certification_btn" round type="info" color="#0767ab" @click="mobileShow01 = fasle">知道了
        </van-button>
      </div>
    </van-dialog>
    <!-- 手机号已注册 -->
    <van-dialog v-model="mobileShow02" :show-confirm-button="false">
      <div class="mobileShow_dialog flex-c-c-c">
        <van-image width="3.89rem" height="3.04rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/certification.png"
        />
        <div class="certification_title"> 手机号已注册 </div>
        <div class="certification_tip"> 该手机号已注册，可使用该手机号直接登录 </div>
        <van-button class="certification_btn" round type="info" color="#0767ab" @click="mobileShow02 = fasle">知道了
        </van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import './../linkPage/index.scss'
import { districts } from '@/api/public'
import { mobileIsReal, sms_send, authAndReg } from '@/api/user'
export default {
  name: 'CertificationReg02',
  data() {
    return {
      regSignId: '',
      openid: '',

      step: 2,
      certificationShow: true,
      fileList01: [],
      loading: false,
      regStepFrom: {},

      showPicker: false,
      cascadervalue: '',
      columns: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      regInfo: { mobile: '', companyName: '', platformType: 'wxmp', categoryId: 1 },

      value: '',
      errorInfo: '',
      showKeyboard: true,
      time: 60 * 1000,
      isSend: false,

      mobileShow01: false,
      mobileShow02: false

    }
  },
  created() {
    this.regStepFrom = {
      businessLicense: this.$route.query.businessLicense,
      companyName: this.$route.query.companyName,
      legalPerson: this.$route.query.legalPerson,
      ocrSignId: this.$route.query.ocrSignId,
      ocrUrl: this.$route.query.ocrUrl,
      districtName: '', mobile: ''
    }
    this.openid = this.$route.query.openId
    this.loadDistrict()
  },
  methods: {
    backPath() { window.history.back() },
    // 加载省市区
    loadDistrict() { districts().then(res => { this.columns = res.data }) },
    // 选择结束
    finishPick(value) {
      console.log(value)
      this.showPicker = false
      this.regStepFrom.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
      this.regStepFrom.provinceId = value.selectedOptions[0].value
      this.regStepFrom.cityId = value.selectedOptions[1].value
      this.regStepFrom.areaId = value.selectedOptions[2].value

      this.regInfo.provinceId = value.selectedOptions[0].value
      this.regInfo.cityId = value.selectedOptions[1].value
      this.regInfo.areaId = value.selectedOptions[2].value
    },
    // 关闭选择
    closePick() { this.showPicker = false },
    // 点击验证
    onSubmit(values) {
      this.loading = true
      mobileIsReal(this.regStepFrom).then(res => {
        if (res.code === 40024) {
          this.mobileShow01 = true; this.loading = false
        } else if (res.code === 40025) {
          this.mobileShow02 = true; this.loading = false
        } else {
          this.loading = false
          this.regSignId = res.data.regSignId
          this.sendMessage()
        }
      }).catch(() => {

      })
    },
    // 发送短信
    sendMessage() {
      const parms = { mobile: this.regStepFrom.mobile, type: 'login' }
      sms_send(parms).then(res => { this.isSend = true })
    },
    // 倒计时结束触发D
    finish() { this.isSend = false },
    // 注册登录
    regMobile() {
      const parms = { checkCode: this.regStepFrom.checkCode, regSignId: this.regSignId, openId: this.openid }
      authAndReg(parms).then(res => {
        this.$toast({ message: '注册成功！', duration: 3 * 1000 })
        this.$router.push('/user/inviteRegSuccess')
      }).catch(err => {
        console.log(err)
        this.value = ''
      })
    }
  }
}
</script>
